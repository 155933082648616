import { Alert, Button, Modal, Spinner } from 'flowbite-react';
import { MouseEventHandler, useState } from 'react';
import { HiOutlineCollection } from 'react-icons/hi';
import { HiPlus } from 'react-icons/hi';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ShrtQuery from '../../../components/common/ShrtQuery';
import ShrtTable from '../../../components/common/Table';
import { Route } from '../../../config/routes';
import { useShrtMutation } from '../../../hooks/useShrtMutation';
import { Feature } from '../../../models/feature';
import { Link as ApiLink, QrCodeType } from '../../../models/link';
import { apiKeyErrorSelector, selectedApiKeySelector } from '../../../redux/reducers/api-keys/api-keys-reducer';
import { pricingPlanSelector } from '../../../redux/reducers/user/user-reducer';
import { hasFeature, hasProtectedLinkFeature } from '../../../services/features.service';
import { createNewLink, deleteMyLink, getLatestLinks } from '../../../services/shortenify.service';
import { getConsoleLogger } from '../../../utils/LogUtils';
import CreateLinkModal, { CreateLinkModalInput } from './modal/create-modal';
import DeleteLinkModal from './modal/delete-modal';
import ShrtLinkRow from './table/ShrtLinkRow';

const logger = getConsoleLogger('ShrtApiKeyStatistics');

type ShrtApiKeyStatisticsState = {
  openModal: boolean;
  linkId?: string;
  modalType?: 'create' | 'delete';
};

export default function ShrtApiKeyStatistics() {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedApiKey = useSelector(selectedApiKeySelector) ?? null;
  const selectedApiKeyError = useSelector(apiKeyErrorSelector) ?? null;
  const pricingPlan = useSelector(pricingPlanSelector) ?? null;
  const createLinkMutation = useShrtMutation(`latestLinks#${selectedApiKey?.id}`, createNewLink, 'createLink');
  const deleteLinkMutation = useShrtMutation(`latestLinks#${selectedApiKey?.id}`, deleteMyLink, 'deleteLink');
  const [state, setState] = useState<ShrtApiKeyStatisticsState>({ openModal: false });
  const { modalType, openModal, linkId } = state;
  const hasProtectedLink = pricingPlan ? hasProtectedLinkFeature(pricingPlan) : false;

  logger.info('current pricing plan: ', pricingPlan);

  const handleOnCreateLink = () => {
    setState({ modalType: 'create', openModal: true });
  };

  const handleOnDelete = (linkId: string) => {
    setState({ modalType: 'delete', openModal: true, linkId });
  };

  const handleOnCancel = () => {
    setState({ modalType: undefined, openModal: false, linkId: undefined });
  };

  const handleOnCreateConfirm = ({ password, qrCode, url, username }: CreateLinkModalInput) => {
    createLinkMutation.mutate({
      apiKey: selectedApiKey?.id,
      url,
      ...(qrCode ? { qrCodeType: QrCodeType.DATA_URI } : {}),
      ...(username && password ? { credentials: { username, password } } : {}),
    });
    setState({ modalType: undefined, openModal: false });
  };

  const handleOnDeleteConfirm = () => {
    deleteLinkMutation.mutate({ apiKey: selectedApiKey?.id, linkId });
    setState({ modalType: undefined, openModal: false, linkId: undefined });
  };

  const handleShowMore: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    const route = Route.DashboardLinks.replace(':apiKey', selectedApiKey!.id);
    navigate(route);
  };
  const totalLinks = selectedApiKey?.statistics?.totalLinks ?? 0;

  if (selectedApiKeyError !== null) {
    return (
      <div className="flex justify-center">
        <Alert color="warning">
          <FormattedMessage id={selectedApiKeyError} defaultMessage="The key provided does not exist or your don't own it" />
        </Alert>
      </div>
    );
  }

  if (!selectedApiKey || createLinkMutation.isPending || deleteLinkMutation.isPending) {
    return (
      <div className="flex justify-center">
        <Spinner aria-label="loading" />
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <ShrtQuery query={() => getLatestLinks(selectedApiKey.id)} queryKey={`latestLinks#${selectedApiKey.id}`} queryKeyResponse="latestLinks">
        {(data) => {
          return (
            <div>
              <div className="grid xs:grid-cols-1 lg:grid-cols-2 xs:gap-x-0 lg:gap-x-4 gap-y-4">
                <div>
                  <div className="dark:text-white text-center mb-4">
                    <FormattedMessage id="pages.dashboard.api_key_statistics.labels.top_links_opened" defaultMessage="Most opened links" />
                  </div>
                  <ShrtTable
                    emptyDataDefaultLabel="No links opened so far"
                    emptyDataLabel="pages.dashboard.api_key_statistics.labels.no_links_opened"
                    headLabels={[
                      <FormattedMessage id="common.id" defaultMessage="ID" />,
                      hasProtectedLink && <FormattedMessage id="common.protected" defaultMessage="Protected" />,
                      <FormattedMessage id="common.url" defaultMessage="URL" />,
                      <FormattedMessage id="common.redirect_to" defaultMessage="Redirect to" />,
                      <FormattedMessage id="common.opened" defaultMessage="Opened" />,
                      <span className="sr-only">Delete</span>,
                    ]}
                    items={data.hotLinks?.items}
                    renderRow={(link: ApiLink) => (
                      <ShrtLinkRow
                        key={link.id}
                        displayProtected={hasProtectedLink}
                        link={link}
                        pathname={location.pathname}
                        onDeleteHandler={handleOnDelete}
                      />
                    )}
                  />
                </div>
                <div>
                  <div className="dark:text-white text-center mb-4">
                    <FormattedMessage id="pages.dashboard.api_key_statistics.labels.last_created_links" defaultMessage="Last created links" />
                  </div>
                  <ShrtTable
                    emptyDataDefaultLabel="Just click the above button to create your first short link"
                    emptyDataLabel="pages.dashboard.api_key_statistics.labels.no_links_created"
                    headLabels={[
                      <FormattedMessage id="common.id" defaultMessage="ID" />,
                      hasProtectedLink && <FormattedMessage id="common.protected" defaultMessage="Protected" />,
                      <FormattedMessage id="common.url" defaultMessage="URL" />,
                      <FormattedMessage id="common.redirect_to" defaultMessage="Redirect to" />,
                      <FormattedMessage id="common.created" defaultMessage="Created" />,
                      <span className="sr-only">Delete</span>,
                    ]}
                    items={data.lastLinks?.items}
                    renderRow={(link: ApiLink) => (
                      <ShrtLinkRow
                        displayProtected={hasProtectedLink}
                        opened={false}
                        key={link.id}
                        link={link}
                        pathname={location.pathname}
                        onDeleteHandler={handleOnDelete}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="flex justify-center mt-10 space-x-4">
                {totalLinks > 0 && (
                  <div>
                    <Button outline onClick={handleShowMore}>
                      <HiOutlineCollection className="mr-2 h-5 w-5" />
                      <FormattedMessage id="pages.dashboard.api_key_statistics.buttons.view_all" defaultMessage="View all" />
                    </Button>
                  </div>
                )}
                <div>
                  <Button outline onClick={handleOnCreateLink} type="submit">
                    <HiPlus className="mr-2 h-5 w-5" />
                    <FormattedMessage id="pages.dashboard.api_key_statistics.buttons.create_link" defaultMessage="Create a short link" />
                  </Button>
                </div>
              </div>
            </div>
          );
        }}
      </ShrtQuery>
      <Modal show={openModal} size="lg" onClose={handleOnCancel} popup>
        <Modal.Header>
          {modalType === 'create' && <FormattedMessage id="pages.dashboard.api_key_statistics.modal.titles.create" defaultMessage="Create a short link" />}
          {modalType === 'delete' && (
            <FormattedMessage id="pages.dashboard.api_key_statistics.modal.titles.delete" defaultMessage="Delete link {linkId}?" values={{ linkId }} />
          )}
        </Modal.Header>
        <Modal.Body>
          {modalType === 'create' && (
            <CreateLinkModal
              cancel={handleOnCancel}
              confirm={handleOnCreateConfirm}
              canGenerateQrCode={pricingPlan ? hasFeature(pricingPlan, Feature.QrCode) : false}
              canProtectLink={pricingPlan ? hasFeature(pricingPlan, Feature.ProtectedLink) : false}
            />
          )}
          {modalType === 'delete' && <DeleteLinkModal cancel={handleOnCancel} confirm={handleOnDeleteConfirm} />}
        </Modal.Body>
      </Modal>
    </div>
  );
}
