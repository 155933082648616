export const latestLinks = (apiKey: string) => `
query LatestLinks {
  hotLinks(apiKey: "${apiKey}") {
    items {
      created
      id
      opened
      protected
      redirectTo
      url
    }
  }
  lastLinks(apiKey: "${apiKey}") {
    items {
      created
      id
      protected
      redirectTo
      url
    }
  }
}`;
