import { TextInput, TextInputProps } from 'flowbite-react';
import { ErrorMessage, FormikProps } from 'formik';
import { useIntl } from 'react-intl';

type ShrtFormikTextInputProps<T> = TextInputProps & {
  defaultPlaceholder?: string;
  formikProps: FormikProps<T>;
  id: keyof T;
  placeholderId?: string;
};

export default function ShrtFormikTextInput<T>({ id, defaultPlaceholder, placeholderId, formikProps, ...props }: ShrtFormikTextInputProps<T>) {
  const intl = useIntl();
  const placeholder =
    placeholderId && defaultPlaceholder
      ? intl.formatMessage({
          id: placeholderId,
          defaultMessage: defaultPlaceholder,
        })
      : undefined;
  return (
    <TextInput
      id={id}
      placeholder={placeholder}
      color={formikProps.touched[id] && formikProps.errors[id] ? 'failure' : undefined}
      helperText={<ErrorMessage name={id}>{(msg) => <span>{msg}</span>}</ErrorMessage>}
      {...props}
      {...formikProps.getFieldProps(id)}
    />
  );
}
