import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const createLinkValidationSchema = (intl: IntlShape) =>
  Yup.object({
    url: Yup.string()
      .required(
        intl.formatMessage({
          id: 'common.forms.validation.required',
          defaultMessage: 'This field is required',
        }),
      )
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        intl.formatMessage({
          id: 'common.forms.validation.url',
          defaultMessage: 'Invalid HTTP link',
        }),
      ),
    protected: Yup.boolean().optional(),
    username: Yup.string().when('protected', {
      is: true,
      then: (schema) =>
        schema
          .trim()
          .required(
            intl.formatMessage({
              id: 'common.forms.validation.required',
              defaultMessage: 'This field is required',
            }),
          )
          .min(
            3,
            intl.formatMessage(
              {
                id: 'common.forms.validation.minimum',
                defaultMessage: '{minimum} characters minimum',
              },
              { minimum: 3 },
            ),
          )
          .max(
            10,
            intl.formatMessage(
              {
                id: 'common.forms.validation.maximum',
                defaultMessage: '{maximum} characters minimum',
              },
              { maximum: 10 },
            ),
          ),
      otherwise: (schema) => schema.notRequired(),
    }),
    password: Yup.string().when('protected', {
      is: true,
      then: (schema) =>
        schema
          .trim()
          .required(
            intl.formatMessage({
              id: 'common.forms.validation.required',
              defaultMessage: 'This field is required',
            }),
          )
          .min(
            3,
            intl.formatMessage(
              {
                id: 'common.forms.validation.minimum',
                defaultMessage: '{minimum} characters minimum',
              },
              { minimum: 3 },
            ),
          )
          .max(
            10,
            intl.formatMessage(
              {
                id: 'common.forms.validation.maximum',
                defaultMessage: '{maximum} characters minimum',
              },
              { maximum: 10 },
            ),
          ),
      otherwise: (schema) => schema.notRequired(),
    }),
  });
