export type LinkDeviceOS = {
  android?: number;
  iOS?: number;
};

export type LinkDevicesInformation = {
  OS?: LinkDeviceOS;
};

export type LinkCountry = {
  country: string;
  opened: number;
};

export type LinkPlatforms = {
  desktop?: number;
  mobile?: number;
  tablet?: number;
};

export type LinkSegments = {
  countries: LinkCountry[];
  devices: LinkDevicesInformation;
  platforms: LinkPlatforms;
};

export type Link = {
  created: string;
  id: string;
  lastOpened?: string;
  opened: number;
  protected?: boolean;
  qrCode?: LinkQrCode;
  redirectTo: string;
  segments?: LinkSegments;
  statistics?: {
    items: LinkStatistics[];
  };
  url: string;
};

export type LinkConnection = {
  items: Link[];
  nextToken: string;
  totalCount: number;
};

export type LinkQrCode = {
  type: QrCodeType;
  value: string;
};

export type LinkStatistics = {
  date: string;
  id: string;
  opened: number;
};

export enum LinkStatisticsPeriod {
  Daily = 'daily',
  Monthly = 'monthly',
}

export enum QrCodeType {
  DATA_URI = 'data',
  SVG = 'svg',
}
