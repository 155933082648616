import { Button, Label, ToggleSwitch } from 'flowbite-react';
import { Formik } from 'formik';
import { HiCheck, HiX } from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';
import ShrtFormikTextInput from '../../../../components/common/ShrtFormikTextInput';
import { createLinkValidationSchema } from './validation/create-modal_validation';

type CreateLinkModalProps = {
  cancel: () => void;
  confirm: (input: CreateLinkModalInput) => void;
  canGenerateQrCode: boolean;
  canProtectLink: boolean;
};

export type CreateLinkModalInput = {
  qrCode?: boolean;
  password?: string;
  protected?: boolean;
  url: string;
  username?: string;
};

export default function CreateLinkModal({ cancel, confirm, canGenerateQrCode, canProtectLink }: CreateLinkModalProps) {
  const intl = useIntl();
  const handleOnCancel = () => cancel();
  const urlLabel = intl.formatMessage({ id: 'pages.dashboard.api_key_statistics.modal.labels.create_link', defaultMessage: 'Enter the URL to shorten:' });
  const qrCodeLabel = intl.formatMessage({ id: 'pages.dashboard.api_key_statistics.modal.labels.qr_code', defaultMessage: 'Generate a QR code' });
  const protectLabel = intl.formatMessage({
    id: 'pages.dashboard.api_key_statistics.modal.labels.protect',
    defaultMessage: 'Protect your link with a password:',
  });
  const usernameLabel = intl.formatMessage({ id: 'pages.dashboard.api_key_statistics.modal.labels.username', defaultMessage: 'Username' });
  const passwordLabel = intl.formatMessage({ id: 'pages.dashboard.api_key_statistics.modal.labels.password', defaultMessage: 'Password' });

  const handleOnSubmit = (values: any) =>
    confirm({ qrCode: values.qrCode, url: values.url, ...(values.protected ? { username: values.username, password: values.password } : {}) });

  return (
    <>
      <Formik
        initialValues={{
          qrCode: false,
          password: '',
          protected: false,
          url: '',
          username: '',
        }}
        onSubmit={handleOnSubmit}
        validationSchema={createLinkValidationSchema(intl)}
      >
        {(props) => (
          <form className="space-y-6" onSubmit={props.handleSubmit}>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="url" value={urlLabel} />
              </div>
              <ShrtFormikTextInput
                autoComplete="off"
                defaultPlaceholder="Enter the URL to shorten"
                formikProps={props}
                id="url"
                placeholderId="pages.dashboard.api_key_statistics.modal.placeholders.create_link.url"
              />
            </div>
            {canGenerateQrCode && (
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="url" value={qrCodeLabel} />
                </div>
                <ToggleSwitch checked={props.values.qrCode ?? false} onChange={(checked) => props.setValues({ ...props.values, qrCode: checked })} />
              </div>
            )}
            {canProtectLink && (
              <div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="protected" value={protectLabel} />
                  </div>
                  <ToggleSwitch
                    checked={props.values.protected ?? false}
                    {...props.getFieldProps('protected')}
                    onChange={(checked) => props.setValues({ ...props.values, protected: checked })}
                  />
                </div>
                {props.values.protected && (
                  <div className="mt-5">
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="username" value={usernameLabel} />
                      </div>
                      <ShrtFormikTextInput
                        autoComplete="off"
                        defaultPlaceholder="my_top_secret_user"
                        formikProps={props}
                        id="username"
                        placeholderId="pages.dashboard.api_key_statistics.modal.placeholders.create_link.username"
                      />
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="password" value={passwordLabel} />
                      </div>
                      <ShrtFormikTextInput
                        formikProps={props}
                        id="password"
                        defaultPlaceholder="my_super_secret_password"
                        placeholderId="pages.dashboard.api_key_statistics.modal.placeholders.create_link.password"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="flex justify-center gap-4">
              <Button color="gray" onClick={handleOnCancel}>
                <HiX className="mr-2 h-5 w-5" />
                <FormattedMessage id="common.buttons.cancel" defaultMessage="Delete" />
              </Button>
              <Button color="success" type="submit" disabled={props.isSubmitting}>
                <HiCheck className="mr-2 h-5 w-5" />
                <FormattedMessage id="common.buttons.create" defaultMessage="Create" />
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
