export const getLinks = (apiKey: string, after?: string) => `
query GetLinks {
  getLinks(apiKey: "${apiKey}"${after ? `, after: "${after}"` : ''}) {
      items {
        id
        opened
        protected
        redirectTo
        url
      }
      nextToken
      totalCount
  }
}`;
