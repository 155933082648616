import { Button, Label } from 'flowbite-react';
import { Formik } from 'formik';
import { HiCheck, HiX } from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';
import ShrtFormikTextInput from '../../../../components/common/ShrtFormikTextInput';
import { deleteLinkValidationSchema } from './validation/delete-modal_validation';

type DeleteLinkModalProps = {
  cancel: () => void;
  confirm: () => void;
};

export default function DeleteLinkModal({ cancel, confirm }: DeleteLinkModalProps) {
  const intl = useIntl();
  const label = intl.formatMessage({
    id: 'pages.dashboard.api_key_statistics.modal.labels.confirm_delete',
    defaultMessage: 'Please confirm by typing "delete":',
  });

  const handleOnSubmit = () => confirm();

  const handleOnCancel = () => cancel();

  return (
    <Formik
      initialValues={{
        confirmation: '',
      }}
      onSubmit={handleOnSubmit}
      validationSchema={deleteLinkValidationSchema(intl)}
    >
      {(props) => (
        <form className="space-y-6" onSubmit={props.handleSubmit}>
          <p className="text-gray-500 dark:text-gray-400">
            <FormattedMessage
              id="pages.dashboard.api_key_statistics.modal.labels.delete_link"
              defaultMessage="Are you sure you want to delete this link? This action is not reversible."
            />
          </p>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="confirmation" value={label} />
            </div>
            <ShrtFormikTextInput
              autoComplete="off"
              defaultPlaceholder="delete"
              formikProps={props}
              id="confirmation"
              placeholderId="pages.dashboard.api_key_statistics.modal.placeholders.delete"
            />
          </div>
          <div className="flex justify-center gap-4">
            <Button color="gray" onClick={handleOnCancel}>
              <HiX className="mr-2 h-5 w-5" />
              <FormattedMessage id="common.buttons.cancel" defaultMessage="Delete" />
            </Button>
            <Button color="success" type="submit">
              <HiCheck className="mr-2 h-5 w-5" />
              <FormattedMessage id="common.buttons.delete" defaultMessage="Delete" />
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}
